import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-container',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './container.component.html',
  styleUrl: './container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent { 
  @Input() maxWidth: number = 1200;
  @Input() backgroundColor: 'bg-white' | 'bg-gradient' = 'bg-white';
}
